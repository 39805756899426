import { Grow, List, Stack } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { TransitionGroup } from 'react-transition-group'
import { type Quest } from './hooks/useQuests'
import QuestListHeader from './QuestListHeader'
import QuestListItem from './QuestListItem'
export interface QuestListProps {
    quests: Quest[]
    onShowAllQuests: () => void | null
}

export const QuestList = ({ quests, onShowAllQuests }: QuestListProps) => {
    const { spacing } = useTheme()

    return (
        <Stack sx={{ gap: spacing(2) }}>
            <QuestListHeader onShowAllQuests={onShowAllQuests} />
            <TransitionGroup
                component={List}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: spacing(2)
                }}
            >
                {quests.map((quest) => {
                    return (
                        <Grow key={quest.id} timeout={300} easing="ease-in-out">
                            <QuestListItem quest={quest} />
                        </Grow>
                    )
                })}
            </TransitionGroup>
        </Stack>
    )
}
