import { ListItem, Stack } from '@mui/material'
import {
    LeafIcon,
    useTheme,
    CoreTypography
} from '@thriveglobal/thrive-web-leafkit'
import { forwardRef, memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ActionLabel } from './ActionLabel'
import { Quest } from './hooks/useQuests'
import { useNavigation } from '@thriveglobal/thrive-web-core'
import { ActionType } from '../../../graphql/generated/autogenerated'
import { RESET_ROUTES } from '../../../routes/routes'
import { QuestProgressBar } from './QuestProgressBar/QuestProgressBar'
import { QuestStatusIcon } from './QuestStatusIcon/QuestStatusIcon'

type QuestRedirectOptions = {
    link: string
}

const QUEST_ACTION_TYPE_TO_REDIRECT_MAP = {
    [ActionType.ResetWatched]: {
        link: RESET_ROUTES.RESETS
    }
} as const

function getQuestRedirectOptions(
    questActionType: ActionType
): QuestRedirectOptions | null {
    const redirectConfig = QUEST_ACTION_TYPE_TO_REDIRECT_MAP[questActionType]
    return redirectConfig ?? null
}

interface QuestListItemProps {
    quest: Quest
}

const QuestListItem = forwardRef<HTMLDivElement, QuestListItemProps>(
    (props, ref) => {
        const { quest } = props
        const { spacing, palette } = useTheme()
        const intl = useIntl()

        const navigate = useNavigation()
        const redirectOptions = getQuestRedirectOptions(quest.actionType)

        const progress = (quest.completedSoFar / quest.maxCanBeCompleted) * 100
        const hasRedirectSupport = !!redirectOptions

        const actionLabelId = `action-label-${quest.id}`

        return (
            <ListItem
                sx={{
                    p: 0,
                    m: 0
                }}
            >
                <Stack
                    ref={ref}
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: spacing(1.25),
                        color: palette.text.primary,
                        textDecoration: 'none',
                        width: '100%'
                    }}
                    component={hasRedirectSupport ? 'a' : 'div'}
                    href={hasRedirectSupport ? redirectOptions.link : undefined}
                    onClick={
                        hasRedirectSupport
                            ? (event) => {
                                  event.preventDefault()
                                  navigate(redirectOptions.link)
                              }
                            : undefined
                    }
                >
                    <QuestStatusIcon
                        actionType={quest.actionType}
                        completedCount={quest.completedSoFar}
                        requiredCount={quest.maxCanBeCompleted}
                    />
                    <Stack flex={1} gap={spacing(0.5)}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <CoreTypography variant="body2" id={actionLabelId}>
                                <ActionLabel
                                    requiredCount={quest.maxCanBeCompleted}
                                    actionType={quest.actionType}
                                />
                            </CoreTypography>
                            {hasRedirectSupport && (
                                <LeafIcon
                                    icon="chevron-right"
                                    fontSize="small"
                                />
                            )}
                        </Stack>
                        <QuestProgressBar
                            actionType={quest.actionType}
                            ariaLabelId={actionLabelId}
                            progress={progress}
                        />
                    </Stack>
                </Stack>
            </ListItem>
        )
    }
)

export default memo(QuestListItem)
