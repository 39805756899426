import { ActionType } from '../../graphql/generated/autogenerated'

/**
 * Supported action types are those that have redirect options in the game
 */
export type SupportedActionType =
    | ActionType.AssessmentCompleted
    | ActionType.CheckIn
    | ActionType.ContentFinished
    | ActionType.IntentionSettingCompleted
    | ActionType.PulseSurveyCompleted
    | ActionType.ResetWatched

export const supportedActionTypes = [
    ActionType.AssessmentCompleted,
    ActionType.CheckIn,
    ActionType.ContentFinished,
    ActionType.IntentionSettingCompleted,
    ActionType.PulseSurveyCompleted,
    ActionType.ResetWatched
] satisfies SupportedActionType[]
/**
 * Returns an array of action types that are supported in the game
 * These are actions that have redirect options and can be interacted with
 */
export function getSupportedActionTypes({
    isBaselineAssessmentEnabled = true
}: { isBaselineAssessmentEnabled?: boolean } = {}): SupportedActionType[] {
    if (!isBaselineAssessmentEnabled) {
        return supportedActionTypes.filter(
            (actionType) => actionType !== ActionType.AssessmentCompleted
        )
    }
    return supportedActionTypes
}

/**
 * Type guard to check if an action type is supported
 */
export function isSupportedActionType(
    actionType: ActionType
): actionType is SupportedActionType {
    return getSupportedActionTypes().includes(actionType as SupportedActionType)
}
