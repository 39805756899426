import { Box, colors } from '@mui/material'
import {
    LeafIcon,
    useTheme,
    CoreTypography
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'
import { type ActionType } from '../../../../graphql/generated/autogenerated'
import {
    useGetActionTypeColor,
    useGetGamePalette
} from '../../../../hooks/useGetGamePalette/useGetGamePalette'
interface QuestStatusIconProps {
    actionType: ActionType
    completedCount: number
    requiredCount: number
}

const messages = defineMessages({
    completedAria: {
        defaultMessage: 'Daily quest completed!',
        description: 'Quest completed count'
    },
    inProgressAria: {
        defaultMessage: '{completed} out of {required} actions completed',
        description: 'Quest in progress count'
    }
})

export function QuestStatusIcon({
    actionType,
    completedCount,
    requiredCount
}: QuestStatusIconProps) {
    const theme = useTheme()
    const intl = useIntl()
    const color = useGetActionTypeColor(actionType)

    const isCompleted = completedCount >= requiredCount

    return (
        <Box
            data-testid="quest-list-item-icon"
            role="img"
            sx={{
                p: theme.spacing(1),
                borderRadius: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: color?.light
            }}
            aria-label={intl.formatMessage(
                isCompleted ? messages.completedAria : messages.inProgressAria,
                {
                    completed: completedCount,
                    required: requiredCount
                }
            )}
        >
            {isCompleted ? (
                <LeafIcon
                    data-testid="quest-list-item-icon-check"
                    aria-hidden
                    icon="check"
                    fontSize="small"
                    sx={{
                        minWidth: theme.spacing(3),
                        minHeight: theme.spacing(2.5)
                    }}
                />
            ) : (
                <CoreTypography
                    aria-hidden
                    variant="overline"
                    fontWeight={600}
                    color={theme.palette.text.primary}
                >
                    {completedCount}/{requiredCount}
                </CoreTypography>
            )}
        </Box>
    )
}
