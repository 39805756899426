import { Grid, List, ListItem } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { Frequency } from '../../../../graphql/generated/autogenerated'
import { useQuests } from '../hooks/useQuests'
import QuestCard from './QuestCard'
import { QuestCardSkeleton } from './QuestCardSkeleton'
import { useMemo } from 'react'

export interface QuestTabContentProps {
    frequency: Frequency
    onActionForCurrentRoute?: () => void
}

export const QuestTabContent = ({
    frequency,
    onActionForCurrentRoute
}: QuestTabContentProps) => {
    const { spacing } = useTheme()
    const { quests, loading } = useQuests({
        frequency,
        maxUncompletedQuests: 'all' // Show all quests in the modal
    })

    const skeletonCount = frequency === Frequency.TimeWindowBased ? 4 : 2

    const skeletonItems = useMemo(() => {
        if (!loading) {
            return null
        }
        return Array.from({ length: skeletonCount }, (_, index) => (
            <Grid item xs={12} md={6} key={`skeleton-${index}`}>
                <QuestCardSkeleton />
            </Grid>
        ))
    }, [skeletonCount, loading])

    if (loading) {
        return (
            <Grid container spacing={2}>
                {skeletonItems}
            </Grid>
        )
    }

    return (
        <Grid container spacing={2} component={List}>
            {quests.map((quest) => (
                <Grid item xs={12} md={6} key={quest.id} component={ListItem}>
                    <QuestCard
                        quest={quest}
                        frequency={frequency}
                        onActionForCurrentRoute={onActionForCurrentRoute}
                    />
                </Grid>
            ))}
        </Grid>
    )
}
