import { LinearProgress } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages, useIntl } from 'react-intl'
import { type ActionType } from '../../../../graphql/generated/autogenerated'
import { useGetGamePalette } from '../../../../hooks/useGetGamePalette/useGetGamePalette'

interface QuestProgressBarProps {
    progress: number
    ariaLabelId?: string
    actionType: ActionType
}

const messages = defineMessages({
    progressBarAriaLabel: {
        defaultMessage: 'Percentage of actions completed',
        description: 'Quest progress bar aria label'
    }
})

export function QuestProgressBar({
    progress,
    ariaLabelId,
    actionType
}: QuestProgressBarProps) {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const palette = useGetGamePalette()
    const colors = palette[actionType] || {
        main: theme.palette.primary.main,
        light: theme.palette.primary.light
    }

    return (
        <LinearProgress
            variant="determinate"
            value={progress}
            {...(ariaLabelId
                ? {
                      'aria-labelledby': ariaLabelId
                  }
                : {
                      'aria-label': formatMessage(messages.progressBarAriaLabel)
                  })}
            sx={{
                height: theme.spacing(2),
                borderRadius: theme.spacing(1.5),
                '& .MuiLinearProgress-bar': {
                    backgroundColor: colors.main,
                    borderRadius: theme.spacing(1.5)
                },
                backgroundColor: colors.light
            }}
        />
    )
}
