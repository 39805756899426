import { type SupportedActionType } from '../../utils/game/getSupportedActionTypes'
import { ActionType } from '../../graphql/generated/autogenerated'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { alpha } from '@mui/material'
import { useMemo } from 'react'

/**
 * Type for a color variant in the game palette
 */
export interface GameColor {
    main: string
    light: string
}

/**
 * Type for the game palette, mapping each supported action to its color variants
 */
export type GamePalette = {
    [K in SupportedActionType]: GameColor
}

/**
 * Hook that returns a palette object with colors for each supported action type
 * Each action type has a main color and a light variant (30% opacity by default)
 */
export function useGetGamePalette(): GamePalette {
    const { palette } = useTheme()

    return useMemo(
        () => ({
            [ActionType.AssessmentCompleted]: {
                main: palette.success.main,
                light: palette.success.light
            },
            [ActionType.CheckIn]: {
                main: palette.dataVisualization.qualitative.secondary.one,
                light: alpha(
                    palette.dataVisualization.qualitative.secondary.one,
                    0.3
                ) // TODO: Come back to this, there seem to be color contrast issues with this
            },
            [ActionType.ContentFinished]: {
                main: palette.success.main,
                light: palette.success.light
            },
            [ActionType.IntentionSettingCompleted]: {
                main: palette.success.main,
                light: palette.success.light
            },
            [ActionType.PulseSurveyCompleted]: {
                main: palette.dataVisualization.qualitative.secondary.two,
                light: alpha(
                    palette.dataVisualization.qualitative.secondary.two,
                    0.3
                ) // TODO: Come back to this, there seem to be color contrast issues with this
            },
            [ActionType.ResetWatched]: {
                main: palette.dataVisualization.qualitative.secondary.four,
                light: alpha(
                    palette.dataVisualization.qualitative.secondary.four,
                    0.3
                ) // TODO: Come back to this, there seem to be color contrast issues with this
            }
        }),
        [palette]
    )
}

/**
 * Hook that returns the color variants for a specific action type
 * @param actionType The action type to get the colors for
 * @returns The color variants for the action type, or undefined if not supported
 */
export function useGetActionTypeColor(
    actionType: ActionType
): GameColor | undefined {
    const palette = useGetGamePalette()
    return useMemo(
        () => palette[actionType as SupportedActionType],
        [palette, actionType]
    )
}
